.jackpot-home-widget {
  --widget-bg-bf: url('#{$images-path-static}other/jackpot-home-widget/bf-background-mobile-v2.png');
  --widget-bg-cash-mesh: url('#{$images-path-static}other/jackpot-home-widget/cash-mesh-background-mobile-v2.png');

  --widget-height: 80px;
  --widget-width: 100%;

  --widget-value-fs: 32px;
  --widget-value-line-height: 38px;

  --widget-border-radius: 24px;
  --widget-border-width: 2px;

  &.cash-mesh-jackpots-widget {
    --widget-bg: var(--widget-bg-cash-mesh);
  }

  &.bf-jackpot-widget, &.bf-daily-jackpot-widget {
    --widget-bg: var(--widget-bg-bf);
    --widget-title-animation: jackpotBounceEffect 4s 3s infinite both;
  }

  overflow: hidden;
  position: relative;
  width: var(--widget-width);
  height: var(--widget-height);
  margin-bottom: 22px;
  border-radius: var(--widget-border-radius);

  &::after {
    opacity: 0.3;
    content: ' ';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background-image: var(--widget-bg);
    background-position: bottom center;
    background-size: cover;
    border-radius: var(--widget-border-radius);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(60deg, #e94541, #8b1f36, #fbcd00, #6c2e6d);
    animation: jackpotAnimaitedGradient 3s ease alternate infinite;
    background-size: 300% 300%;
    opacity: 0.2;
    border-radius: 24px;
    z-index: 1;
  }

  &__content {
    z-index: 1;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 100%;
    padding: 0 12px;
    border-radius: var(--widget-border-radius);
  }

  &__item {
    display: grid;
    flex-shrink: 0;
    width: 100%;

    &-wrapper {
      position: absolute;
      left: var(--widget-slider-left, 50%);
      transform: var(--widget-slider-transform, translateX(-50%));
    }

    &-list {
      display: flex;
      align-items: center;
      width: 300px;
      margin: 0 auto;
      overflow: hidden;
    }

    &-title {
      color: var(--csb-brand);
      font-weight: 800;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      letter-spacing: -0.02em;
      animation: var(--widget-title-animation);
    }

    &-arrow {
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: var(--radius-s);
      background: rgba(255, 255, 255, 0.1);

      &:active {
        background: rgba(255, 255, 255, 0.2);
      }

      &--right {
        margin-left: auto;
      }

      path {
        fill: var(--white);
      }
    }

    &-value {
      overflow: hidden;
      display: inline-flex;
      height: var(--widget-value-line-height);
      margin: 0 auto;
      line-height: var(--widget-value-line-height);
      font-weight: 800;
      font-size: var(--widget-value-fs);
      color: var(--f-white);

      &__entry {
        width: calc(var(--widget-value-fs) / 1.77);

        &:not([data-value]) {
          display: none;
        }

        &[data-value=','],
        &[data-value='.'] {
          width: calc(var(--widget-value-fs) / 3);

          &::before {
            content: attr(data-value);
          }
        }

        &.smooth::before {
          transition:
            var(--animation-offset, 1000ms) cubic-bezier(0.75, 0.15, 0.6, 1),
            text-shadow 150ms;
        }

        &::before {
          content: ' 0 1 2 3 4 5 6 7 8 9 0 1 2 3 4 5 6 7 8 9';
          display: block;
          word-break: break-word;
        }

        @for $i from 1 through 19 {
          &[data-value='#{$i}']::before {
            margin-top: calc(var(--widget-value-line-height) * -#{$i});
          }
        }
      }
    }
  }

  &__overlay {
    overflow: hidden;
    z-index: 1;
    border-radius: var(--widget-border-radius);

    &,
    &::after {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      content: ' ';
    }

    &::after {
      z-index: 3;
      opacity: 0;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      pointer-events: none;
      transform: skew(30deg);
      animation: jackpotShineEffect 5.75s linear infinite;
    }

    &::before {
      content: ' ';
      width: calc(100% - var(--widget-border-width) * 2);
      height: calc(100% - var(--widget-border-width) * 2);
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      position: absolute;
      border-radius: var(--widget-border-radius);
      background-image: var(--widget-bg);
      background-position: bottom center;
      background-size: cover;
    }
  }

  @keyframes jackpotShineEffect {
    0% {
      left: -50%;
      opacity: 0;
    }
    10% {
      left: 25%;
      opacity: 0.5;
    }
    20% {
      left: 100%;
      opacity: 0;
    }
    100% {
      left: 100%;
      opacity: 0;
    }
  }

  @keyframes jackpotAnimaitedGradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes jackpotBounceEffect {
    0% {
      transform: scale(1);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }

    10% {
      transform: scale(0.95);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }

    15% {
      transform: scale(1.1);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }

    20% {
      transform: scale(1);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }

    25% {
      transform: scale(1.1);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }

    30% {
      transform: scale(1);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }

    to {
      transform: scale(1);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
}
