.subpages-header {
  .main-nav {
    z-index: 26;

    &__container {
      background-color: var(--black);
    }
  }
}

.live-agent {
  .main-nav__container {
    height: 127px;
    align-items: flex-start;
  }
  .game-frame__container {
    .play-game  {
      bottom: calc(var(--bottom-nav-height) + 53px);
    }
  }
}

.main-nav {
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  height: var(--nav-height, var(--bottom-nav-height));
  width: 100%;
  max-width: var(--nav-max-width, var(--container-max-width));
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  touch-action: manipulation;
  cursor: pointer;

  &__container {
    display: flex;
    height: var(--nav-height, var(--bottom-nav-height));
    background-color: var(--nav-bg, var(--black));
    box-shadow: 0 -2px 19px 6px var(--nav-bg, var(--black));
  }

  @include breakpoint-min(768px) {
    left: 50%;
    transform: translateX(-50%);
  }

  &-entry {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 10px;
    color: var(--nav-color, var(--f-grey-2));
    flex: 1 1 100%;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.05em;

    &.is-active {
      color: var(--nav-color-active, var(--white));
    }

    &__title {
      overflow: hidden;
      width: 100%;
      margin-top: 5px;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      min-height: 18px;
    }
  }

  &--selected {
    .main-nav-entry {
      width: 60px;
      height: calc(100% - 14px);
      padding: 0;
      margin: 7px 0;
      flex: none;
      border-right: 2px solid var(--line-primary);
      color: var(--white);

      @include breakpoint-min(390px) {
        width: 90px;
      }
    }

    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      margin: 0 10px;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: -0.05em;
      color: #bababa;

      @include breakpoint-min(390px) {
        margin: 0 10px 0 30px;
      }

      span {
        color: var(--white);
      }
    }

    &-btns {
      display: flex;

      .btn {
        width: 60px;
        height: 30px;
        margin-right: 10px;
        padding: 5px;
        background-color: var(--bg-primary);
        border: 2px solid var(--bg-primary);
        font-size: 12px;
        font-weight: 700;
        color: var(--white);

        &:last-child {
          margin-right: 0;
        }

        &--top {
          background-color: var(--bg-body);
          color: var(--bg-primary);
        }
      }
    }
  }
}
